<template>
    <b-overlay :show="isLoadingData || isSavingNewsletter">
        <b-card>  
            <div class="head-btn">
                <b-button
                    :to="{ name: 'owners-newsletter'}"               
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"                                  
                >
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>       
            <ValidationObserver ref="createNewsLetters" v-slot="{ invalid }">
                <b-form @submit.prevent="save">
                <b-row>
                    <b-col md="3">
                        <ValidationProvider name="lenguaje" rules="required">
                        <b-form-group label="Lenguaje*" slot-scope="{ valid, errors }">
                            <b-form-select
                            :state="errors[0] ? false : valid ? true : language != '' ? true : false"
                            v-model="language"
                            >
                            <option value="" disabled>Seleccione Lenguaje</option>
                            <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                                {{ lang.name }}
                            </option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="3">
                    <ValidationProvider rules="required" name="nombre">
                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="name"
                            :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col md="3">
                    <ValidationProvider rules="required" name="descripcion">
                        <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="description"
                            :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col md="3">
                    <ValidationProvider rules="required" name="link">
                        <b-form-group label="Link" slot-scope="{ valid, errors }">
                        <b-form-input
                            class="form-control"
                            type="text"
                            v-model="link"
                            :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                    <div class="float-left pt-2 mt-1">
                        <b-form-checkbox
                        v-model="status"
                        :value="true"
                        class="custom-control-success"
                        >
                        Activado
                        </b-form-checkbox>
                    </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                    <div class="float-right">
                        <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isSavingNewsletter"
                        >
                        Guardar
                        </b-button>
                    </div>
                    </b-col>
                </b-row>
                </b-form>
            </ValidationObserver>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },

    components: {
       
    }, 
    async created(){
        if( !this.languages.length){
            await this.init()
        }
    },
    data() {
        return {
            name: "",
            description: "",
            link: "",
            status: true,
            isOwner: true,
            language:"",
            isSavingNewsletter: false,
            isLoadingData: false,
        };
    },      
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("start", ["languages"])
    },
    methods: {
        ...mapMutations("fivesClubCatalogs", ["setNewsLetters"]),
        ...mapActions("fivesClubCatalogs", ['getInitialContentForNewsletter',"saveNewsLetters", "fetchNewsLetters"]),
        ...mapMutations('start',['setLanguages']),

        async init(){
            this.isLoadingData = true
            const { languages } = await this.getInitialContentForNewsletter()
            this.setLanguages(languages)
            this.isLoadingData = false
        },
        async save() {
            this.isSavingNewsletter = true;
            const payload = {
                idUser: this.user.idUser,
                name: this.name,
                description: this.description,
                link: this.link,
                status: Boolean(this.status),
                language:this.language
            }

            const { status, message } = await this.saveNewsLetters(payload); // saving in backend

            if (status) {
                showAlertMessage( "Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right")
                Object.assign(this.$data, this.$options.data());
                this.$refs.createNewsLetters.reset(); //reset form
			    this.$router.push({ name: 'owners-newsletter' })					
            }   

            this.isSavingNewsletter = false

        },
    },    
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
}
</style>
